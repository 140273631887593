require('./bootstrap');
require('jquery');
require('datatables.net-bs5');
require('datatables.net-fixedcolumns-bs5');
require('datatables.net-fixedheader-bs5');
require('datatables.net-responsive-bs5');
require('datatables.net-scroller-bs5');
require('datatables.net-select-bs5');
require('@fortawesome/fontawesome-free/js/all.js');
require( 'datatables.net-rowgroup-bs5' );
